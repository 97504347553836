import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h1>PAGE NOT FOUND</h1>
    <p>The requested URL was not found on this server.</p>
  </Layout>
);

export default NotFoundPage;
